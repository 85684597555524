// Overrides
@if $bulmaswatch-import-font {
  @import url("https://fonts.googleapis.com/css?family=Raleway:400,700&display=swap");
}

a {
  transition: all 200ms ease;
}

.button {
  padding-left: 1em;
  padding-right: 1em;
  border-radius: 50px;

  &.is-small {
    border-radius: 40px;
    font-size: 0.85rem;
  }
}

.button {
  transition: all 200ms ease;

  &.is-active,
  &.is-focused,
  &:active,
  &:focus {
    box-shadow: 0 0 0 2px rgba($button-active-border-color, 0.3);
  }
  @each $name, $pair in $colors {
    $color: nth($pair, 1);
    $color-invert: nth($pair, 2);

    &.is-#{$name} {
      &.is-active,
      &.is-focused,
      &:active,
      &:focus {
        box-shadow: 0 0 0 2px rgba($color, 0.3);
      }
    }
  }
}

.content {
  font-size: 1.1rem;
  font-family: $family-serif;

  .button {
    font-family: $family-sans-serif;
  }
}

.card-header-title,
.menu-label,
.message-header,
.modal-card-title,
.panel-heading,
.subtitle,
.title,
h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 700;
  font-family: $family-heading;
}

blockquote {
  font-style: italic;
}

.input,
.textarea {
  transition: all 200ms ease;
  box-shadow: none;
}

.notification {
  @each $name, $pair in $colors {
    $color: nth($pair, 1);
    $color-invert: nth($pair, 2);

    &.is-#{$name} {
      a:not(.button) {
        color: $color-invert;
        text-decoration: underline;
      }
    }
  }
}

.navbar {
  border: 1px solid $border;
}

.hero {
  .navbar {
    border: none;
    box-shadow: 0 1px 0 rgba($border, 0.25);
  }
}

.card {
  $card-border-color: $grey-darker;
  box-shadow: 0 0 1px $grey-light;

  .card-header {
    box-shadow: none;
    border-bottom: 1px solid $grey-lighter;
  }
}
